import React from 'react';
import './style.css'
import a from './KriyaSiddhas/1.png'
import b from './KriyaSiddhas/2.jpg'
import c from './KriyaSiddhas/3.jpg'
import i from './KriyaSiddhas/10.jpg'
import e from './33.png'
import f from './KriyaSiddhas/f.jpg'
import g from './KriyaSiddhas/g.jpg'
import h from './KriyaSiddhas/h.jpg'
import j from './KriyaSiddhas/11.jpg'
import ContactUs from "./ContactUs.js"

const PhotoGallery = () => {
    const photos = [
    { url: e },
    { url: a },
    { url: b },
    { url: c },
    { url: g},
    { url: f},
    { url: h},
    // Add more photo objects with 'url' properties as needed
  ];
  const photos1 = [
    { url: i },
    { url: j },
    // Add more photo objects with 'url' properties as needed
  ];
  return (
    <>
    <div className='cent1'>
    <div className='headz'>
        <h1>Disciples Of Lahiri Baba</h1>
    </div>
    <div className="gallery">
      {photos.map((photo, index) => (
        <div className="gallery-item" key={index}>
            <div className='img-22'>
            <img  src={photo.url} alt={`Photo ${index + 1}`} />

            </div>
        </div>
        
      ))}
    </div>
    <div className='headz'>
        <h1>Disciples Of Swami Shriyukteshwar Giri</h1>
    </div>
    <div className="gallery1">
      {photos1.map((photo, index) => (
        <div className="gallery-item" key={index}>
            <div className='img-22'>
            <img  src={photo.url} alt={`Photo ${index + 1}`} />

            </div>
        </div>
        
      ))}
    </div>
    </div>
    <ContactUs/>
    </>
    
  );
};

export default PhotoGallery;
