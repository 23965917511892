import React from 'react';
import './style.css'
import a from './gallery/a.jpg'
import b from './gallery/b.JPG'
import c from './gallery/c.JPG'
import d from './gallery/d.JPG'
import e from './gallery/e.JPG'
import f from './gallery/f.JPG'
import g from './gallery/g.JPG'
import h from './gallery/h.JPG'
import i from './gallery/i.JPG'
import w from './gallery/w.JPG'
import x from './gallery/x.JPG'
import y from './gallery/y.JPG'
import z from './gallery/z.JPG'
import ab from './gallery/ab.JPG'
import ac from './gallery/ac.JPG'
import aa from './gallery/aa.JPG'
import j from './gallery/a.jpg'
import k from './gallery/k.jpg'
import l from './gallery/l.jpg'
import m1 from './gallery/m1.jpg'
import m from './gallery/m.jpg'
import n from './gallery/n.jpg'
import n1 from './gallery/n1.jpg'
import o from './gallery/o.jpg'
import p from './gallery/p.jpg'
import q from './gallery/q.jpg'
import r from './gallery/r.jpg'
import s from './gallery/s.jpg'
import t from './gallery/t.jpg'
import u from './gallery/u.jpg'
import v from './gallery/v.jpg'
import ad from './gallery/ad.png'
import ContactUs from "./ContactUs.js"


const PhotoGallery = () => {
    const photos = [
    { url: c },
    { url: e },
    { url: f },
    { url: g},
    { url: h },
    { url: i },
    { url: j },
    { url: k },
    { url: l},
    { url: m },
    { url: n },
    { url: m1 },
    { url: n1 },
    { url: o},
    { url: p },
    { url: q },
    { url: r },
    { url: s },
    { url: t},
    { url: u },
    { url: v },
    { url: w },
    { url: x },
    { url: z },
    { url: aa },
    { url: ab },
    { url: ac },
    { url: ad },
    { url: a },
    { url: b},
    // Add more photo objects with 'url' properties as needed
  ];
  return (
    <>
    <div className='cent'>
    <div className='headz'>
        <h1>Youtube Channel Link</h1>
    </div>
    <div> 
      <center>
        <div className='big'>
          <p>Please visit YouTube channel</p>
        </div>
      <a href='https://www.youtube.com/@kriyayogshala/videos'><p className='link'>CLICK THE LINK</p></a>
      <div className='big'>
      <p>Listen to Acharya Dharmendra</p>
      </div>
      

      </center>
      
    </div>

    <div className='headz'>
        <h1>Devotees taking Kriya Diksha</h1>
    </div>
    <div className="gallery">
      {photos.map((photo, index) => (
        <div className="gallery-item" key={index}>
          <img className='imgg' src={photo.url} alt={`Photo ${index + 1}`} />
        </div>
      ))}
    </div>
    </div>
    <ContactUs/>
    </>
    
  );
};

export default PhotoGallery;
