import React from 'react'


export default function Initial() {
  return (
    <>
    <div className='cs'>
          <h1>COMING SOON !!</h1>
    </div>
    </>
  )
}
