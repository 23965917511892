import React from 'react'
import './style.css'
import ContactUs from "./ContactUs.js"
import Gallery from './Gallery.js'

export default function Logo() {
  return (
    <>
    <div className='para'>
        
    <div className="logo-meaning">
        <p className="head">
            <strong>Depiction Of Logo :</strong>
        </p>
        <br/>
        <p className="grey">
            The round yellow shape symolizes gross material world (Mrityu Loka) . White bird is Swan which is a symbol of pure Soul trying to escape from the bondage of attractive force of attachment and to fly in infinite sky of liberation.
        </p><br/>
        <p className='mantra'>
        <strong>"Tanme manah shiv shankalpamastu"</strong>
           
        </p><br/>
        <p className='grey'>
            is a mantra of Yajurveda means "May my right wishes be fulfilled"
        </p><br/>
        <p className='mantra'>
        <strong>"Atmannam viddhi" </strong>
            
        </p><br/>
        <p className='grey'>
           is a great oracle of the Upanishad means "Know thyself and be free"
        </p><br/>
        

    </div>

    </div>
    <ContactUs/>
    </>
  )
}
