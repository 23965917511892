import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import logo from "./1634304804596.png";
import center from "./center.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleDropdownClick1 = () => {
    setDropdown1(!dropdown1);
    setDropdown2(false); // Close the other dropdown
  };

  const handleDropdownClick2 = () => {
    setDropdown2(!dropdown2);
    setDropdown1(false); // Close the other dropdown
  };

  // Add an event listener to handle clicks outside of the dropdowns
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdown1 ||
        dropdown2
      ) {
        // Check if the click is outside of the dropdowns
        if (
          !event.target.classList.contains("nav-links") &&
          !event.target.classList.contains("dropdown-menu1") &&
          !event.target.classList.contains("dropdown-menu")
        ) {
          setDropdown1(false);
          setDropdown2(false);
        }
      }
    }

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdown1, dropdown2]);

  return (
    <>
      <IconContext.Provider value={{ color: "#FFD555" }}>
        <nav className="navbar">
          <div className="navbar-container container">
            <Link to="/Logo" className="navbar-logo" onClick={closeMobileMenu}>
              <img className="img-11" src={logo} alt="Logo" />
              <img className="img-12" src={center} alt="Center" />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <div className="nav-links" onClick={handleDropdownClick1}>
                  Kriya Lineage
                  <i className="fas fa-caret-down"></i>
                </div>
                {dropdown1 && (
                  <ul className="dropdown-menu1">
                    <li className="dropdown-item">
                      <NavLink
                        to="/about"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        <h5>Guru Parampara</h5>
                      </NavLink>
                      <hr />
                      <NavLink
                        to="/Lineage"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        <h5>Other Siddhas</h5>
                      </NavLink>
                    </li>
                    {/* Add more submenu items as needed */}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <div className="nav-links" onClick={handleDropdownClick2}>
                  Kriya Initiation
                  <i className="fas fa-caret-down"></i>
                </div>
                {dropdown2 && (
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <NavLink
                        to="/How"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        <h5>Kriya Diksha</h5>
                      </NavLink>
                      <hr />
                      <NavLink
                        to="/Initial"
                        className={({ isActive }) =>
                          "nav-links" + (isActive ? " activated" : "")
                        }
                        onClick={closeMobileMenu}
                      >
                        <h5>Preparation for Kriya</h5>
                      </NavLink>
                    </li>
                    {/* Add more submenu items as needed */}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Gallery"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Apply"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  Apply
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
