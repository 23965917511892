import React, { useState } from 'react';
import './style.css'
import one from "./1.png"
import two from "./2.png"
import three from "./3.png"
import four from "./4.png"
import five from "./5.png"
import six from "./6.png"
import seven from "./7.png"
import eight from "./8.png"
import nine from "./9.png"
import ten from "./10.png"
import ContactUs from "./ContactUs.js"



export default function Home() {
  const [isButtonOn, setIsButtonOn] = useState(false);

    const toggleButton = () => {
      setIsButtonOn(!isButtonOn);
    };
  return (
    <>
    <div className='but'>
    <div class={`toggle-button ${isButtonOn ? 'on' : 'off'}`} onClick={toggleButton}>
  <div className={`slider ${isButtonOn ? 'on' : 'off'}`} />
  <div className="button-text">
    {!isButtonOn ? 'Hindi' : 'English'}
  </div>
</div>

<div className='name-name'>
  {isButtonOn?(<p>क्रिया योग की प्राचीन परंपरा में आपका स्वागत है</p>):(<p>Welcome To Traditional Path Of Kriya Yoga</p>)}
</div>
    </div>


      {isButtonOn ? (
        
    <div className='cont'>
    <div class="image-stack">
  <img src={one} alt="Image 1"/>
  <img src={two} alt="Image 2"/>
  <img src={three} alt="Image 3"/>
  <img src={four} alt="Image 4"/>
  <img src={five} alt="Image 5"/>
</div>


    </div>
    ) : (
      
      <div className='cont'>
    <div class="image-stack">
  <img src={six} alt="Image 1"/>
  <img src={seven} alt="Image 2"/>
  <img src={eight} alt="Image 3"/>
  <img src={nine} alt="Image 4"/>
  <img src={ten} alt="Image 5"/>
</div>


    </div>
    )}

    <ContactUs/>
    </>
  )
}
