import React from 'react'
import './style.css'

export default function ContactUs() {
  return (
    <>
    <div className='footer'>
        <div className='resp'>
            <p className='name'>CONTACT</p>
            <hr/>
            
        <p>
            Address: New Delhi, Bharatvarsh.
        </p>
        <p>
            Email Address: dacharyakriyayogshala@gmail.com
        </p>
        <div className='copyr'>
        <p > 
            &copy; Copyright 2023 Kriya Yogshala
        </p>

        </div>
        </div>
    </div>
    </>
  )
}
