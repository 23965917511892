import React from 'react'
import './style.css'
import ContactUs from "./ContactUs.js"

export default function How() {
  return (
    <>
    <div className='backgr1'>
      <div className='para'>
      <div className='style-p'>
      <p className='head1'>
        <strong>How to receive Kriya Yoga?</strong>
        </p>
        <p className='other'>
        According to the Bhagavad Gita, Kriya Yoga is a Brahma Vidya (Knowledge to know God). 
        In the Bhagavad Gita, Lord Shri Krishna while teaching Arjuna about Kriya-Yoga said:
        </p><br/>
        <p className='head123'>
        इमं &nbsp;विवस्वते &nbsp;योगं प्रोक्तवानहमव्ययम।
        </p>
        <p className='head12x'>
        विवस्वान्मनवे प्राह मनुरिक्ष्वाकवेऽब्रवीत।। 4:1
        </p><br/>
        <p className='head1234'>
        एवं परम्पराप्राप्तमिमं राजर्षयो विदुः।
        </p>
        <p className='head12'>
        स कालेनेह महता योगो नष्टः परंतप॥ 4:2
        </p><br/>
        <p className='otherb'>
        I gave this imperishable Yoga to Vivasvat (the sun god); Vivasvat passed on the knowledge to Manu (the Hindu law-giver); Manu told it to Ikshwaku (founder of the solar dynasty of the Kshatriyas). 4:1
        </p>
        <p className='otherb'>
        Handed down in this way in orderly succession (<strong><i>Guru disciple tradition</i></strong>), the Rajarishis (royal rishis) knew it. But, O scorcher of Foes (Arjuna)! By the long passage of time, this Yoga was lost sight on the earth. 4:2
        </p>
        <p className='otherb'>
        Thus, It is clear that Kriya-Yoga is directly given by Lord Narayan in the beginning of the creation to Sun god. Due to power of Kriya-Yoga, Sun god received immeasurable heat & radiance and Sun god is considered as the source of energy in the modern scientific world as well. In the second verse, Lord Shri Krishna says that it is learnt through Guru disciple tradition. <strong><i>Following the ancient tradition of Rishis, Kriya Yogshala is strictly committed to impart this Yoga-Vidya through Diksha Samskara individually. Online diksha is never appreciated.</i></strong>
        </p>
        <p className='otherb'>
          Diksha Samsakara is a pious sacrament (Samsakara) of Hindu Sanatan Dharma. In this process, the seeds of sins and ignorance belonging to old incarnations are destroyed and new auspicious righteousness is put upon. Manusmriti, the constitution of Vedic Dharma, says:
        </p>
        <p className='head12'>
        जन्मना जायते शूद्रः, संस्कारात् द्विज उच्यते।
        </p>
        <p className='otherb'>
        “A man possesses imprints of lower consciousness by birth, but after Samsakara he becomes Dwij i.e. twice born” that is he is born again in same body with auspicious righteousness.
Without Diksha Samsakara, no one is authorized to perform Vedic Yajna (Religious rituals). Spiritual actions like Mantra Jap, Yajna, Tantra Kriya, Vedic Karmakand and Yog-Kriya without Diksha can not yield fruit. The main purpose of Diksha is to spiritualize and turn our body, mind and soul toward divinity.

        </p>
      </div>
      </div>
      
    </div>
    <ContactUs/>

    </>
  )
}
