import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import 'Routes' instead of 'Route'
import './App.css';
import Navbar from './navb/Navbar';
import Home from './components/Home';
import About from './components/About';
import How from './components/How';
import Logo from './components/Logo';
import Initial from './components/Initial';
import Gallery from './components/Gallery';
import Lineage from './components/Lineage';
import Apply from './components/Apply';
import ErrorPage from './components/ErrorPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/how" element={<How />} />
          <Route path="/logo" element={<Logo />} />
          <Route path="/initial" element={<Initial />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/lineage" element={<Lineage />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="*" element={<ErrorPage />} /> {/* Fallback route for undefined paths */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
