import React, { useState } from 'react';
import a from './English.pdf';
import b from './Hindi.pdf';
import './style.css';

export default function Apply() {
  const [isButtonOn, setIsButtonOn] = useState(false);

  const toggleButton = () => {
    setIsButtonOn(!isButtonOn);
  };


  return (
    <>
      <div>
        <div class={`toggle-button ${isButtonOn ? 'on' : 'off'}`} onClick={toggleButton}>
          <div className={`slider ${isButtonOn ? 'on' : 'off'}`} />
          <div className="button-text">{!isButtonOn ? 'Hindi' : 'English'}</div>
        </div>
      </div>

      {!isButtonOn ? (
        <div className='pdf'>
          <div className='apply'>
            <br />
            <p>Dear Soul, Welcome to Kriya Yogshala!</p>
            <p><br />
              The truth seeker who is willing to receive Kriya Yoga Initiation is advised to download the
              'application form' provided when mailed and fill in the form that will sketch out your present spiritual life; so
              that it would be easy to understand your present spiritual status and may be guided accordingly to
              the path of self-realization.
            </p><br />
            <p>Always ready to help the Mumukshu.</p>
            <p>Please contact to the following e-mail- <p className='blue'>dacharyakriyayogshala@gmail.com</p></p><br />
            <p>With best wishes,</p><br />
            <p>Kriya Yogshala</p>
            <p>New Delhi, Bharatvarsh.</p><br />
          </div>
        </div>
      ) : (
        <div className='pdf'>
          <div className='apply'>
            <br />
            <p>प्रिये आत्मन्, क्रिया योगशाला में आपका स्वागत है!</p>
            <p><br />
              वे सत्यान्वेषी जो क्रिया योग की दीक्षा लेने को इच्छुक हैं, कृपया नीचे दिये गए ‘आवेदन पत्र’ को डाउनलोड करें (संपर्क करने पर मेल द्वारा प्राप्त किया जाएगा) और अपने आध्यात्मिक जीवन के विषय में वांछित जानकारी साझा करें; ताकि आपके वर्तमान अवस्था को जाना जा सके। तद्नुरूप ही आपको आत्मसाक्षात्कार के पथ पर उचित दिशा-निर्देश दिया जाएगा।
            </p><br />
            <p>आपके आध्यात्मिक उन्नति हेतु सदैव तत्पर।</p>
            <p>कृपया इस ई-मेल पर संपर्क करे- <p className='blue'>dacharyakriyayogshala@gmail.com</p></p><br />
            <p>शुभाकांक्षी</p><br />
            <p>क्रिया योगशाला,</p>
            <p>नई दिल्ली, भारतवर्ष।</p><br />
          </div>
        </div>
      )}
    </>
  );
}
